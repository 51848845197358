<template>
  <div class="container generationApply">
    <div class="container-fluid">
      <div class="flex j-w gener-box conet">
        <span class="title">{{ fileName }}</span>
        <span>共{{ currentTotal }}条</span>
      </div>
      <!-- 列表 -->
      <el-table :data="list" style="width: 100%" max-height="660">
        <el-table-column type="index" width="80" label="序号">
        </el-table-column>
        <el-table-column prop="name" label="姓名" align="center" width="120">
          <template slot-scope="scope">
            <span class="tilte">{{ scope.row.name }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="phone" label="电话" align="center" width="200">
          <template slot-scope="scope">
            <span class="text">{{ scope.row.phone }}</span>
          </template>
        </el-table-column>
        <el-table-column label="科目" align="center" width="200">
          <template slot-scope="scope">
            <span class="text">{{ scope.row.learningSection }}</span>
          </template>
        </el-table-column>
        <el-table-column label="地区" align="center" width="280">
          <template slot-scope="scope">
            <span class="text"
              >{{ scope.row.province }} {{ scope.row.city }}
              {{ scope.row.location }}</span
            >
          </template>
        </el-table-column>
        <el-table-column label="学校" align="center" width="200">
          <template slot-scope="scope">
            <span class="text">{{ scope.row.school }}</span>
          </template>
        </el-table-column>
        <el-table-column label="状态" align="center" width="100">
          <template slot-scope="scope">
            <span v-if="scope.row.isConfirm == null" class="text">待审核</span>
            <span
              v-if="scope.row.isConfirm == 1"
              class="text"
              style="color: #00bd92"
              >报名成功</span
            >
            <span
              v-if="scope.row.isConfirm == 0"
              class="text"
              style="color: #ff5102"
              >报名失败</span
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="page" v-if="currentTotal > 10">
        <el-pagination
          background
          layout="prev, pager, next"
          @current-change="currentChange"
          :total="currentTotal"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import list from '@/mixins/list';
import { agentRegistList } from '@/api/api';
export default {
  name: 'generationApply',
  mixins: [list],
  components: {},
  data() {
    return {
      objFrom: {
        activityId: '',
        fileId: ''
      },
      fileName: ''
    };
  },
  mounted() {
    const { activityId, fileId, fileName } = this.$route.query;
    this.fileName = fileName;
    this.$set(this, 'objFrom', { activityId, fileId });
  },
  methods: {
    loadData() {
      this.$nextTick(() => {
        this.faildList();
      });
    },
    faildList() {
      const param = this.getParams(this.objFrom);
      agentRegistList(param).then((res) => {
        if (res.code == 200) {
          const { list, total } = res.data;
          this.currentTotal = total;
          this.list = list;
        }
      });
    }
  }
};
</script>
<style lang="less" scoped>
@import "~@/assets/style/common";
.generationApply {
  box-sizing: border-box;
  width: 100%;
  .gener-box {
    width: 100%;
    box-sizing: border-box;
    padding: 30px 20px 30px 20px;
    color: #888888;
    font-size: 14px;
    .title {
      font-size: 24px;
      color: #363636;
      flex: 1;
      box-sizing: border-box;
      padding-right: 80px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  .comment();
}
</style>